import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import { percentage } from "../../../../utilities/general";

// Helper function to render table rows
const renderRow = (label, count, total, color) => (
  <View
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 2,
    }}
    key={label}
  >
    <View
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          height: 16,
          backgroundColor: "#e5e7eb",
          borderRadius: 5,
          position: "relative",
        }}
      >
        <View
          style={{
            width: percentage(count, total) + "%",
            height: "100%",
            backgroundColor: color,
            borderRadius: 5,
            position: "absolute",
          }}
        />
      </View>
    </View>
    <View style={{ width: "30%", textAlign: "right", paddingLeft: 10 }}>
      <Text style={{ fontSize: 10, color: "#4b5563" }}>{label}</Text>
    </View>
    <View style={{ width: "10%", textAlign: "left", paddingLeft: 10 }}>
      <Text style={{ fontSize: 10, fontWeight: "bold", color: "#111827" }}>
        {count}
      </Text>
    </View>
  </View>
);

// The main SurveyResultBox component
const SurveyResultBox = ({ question, ratings }) => {
  const totalRatings = ratings.length;
  const generallyAgreePercentage = percentage(
    ratings.filter((rating) => rating >= 4).length,
    totalRatings
  );
  const generallyDisagreePercentage = percentage(
    ratings.filter((rating) => rating <= 2).length,
    totalRatings
  );

  const stronglyAgreeCount = ratings.filter((rating) => rating === 5).length;
  const agreeCount = ratings.filter((rating) => rating === 4).length;
  const neutralCount = ratings.filter((rating) => rating === 3).length;
  const disagreeCount = ratings.filter((rating) => rating === 2).length;
  const stronglyDisagreeCount = ratings.filter((rating) => rating === 1).length;

  return (
    <View
      wrap={false}
      style={{
        border: "1px solid #e5e7eb",
        borderRadius: 10,
        backgroundColor: "#f8fafc",
        padding: 10,
        marginBottom: 20,
      }}
    >
      {/* Table Header */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid #e5e7eb",
          paddingBottom: 10,
          marginBottom: 10,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: "bold",
            color: "#111827",
            flex: 1,
            alignSelf: "center", // Vertically align the question text
          }}
        >
          {question}
        </Text>
        <View
          style={{
            borderLeft: "1px solid #e5e7eb",
            paddingLeft: 10,
            textAlign: "center",
            width: "20%",
            display: "flex",
            alignItems: "center", // Center content horizontally
            justifyContent: "center", // Center content vertically
          }}
        >
          {generallyAgreePercentage >= generallyDisagreePercentage ? (
            <>
              <Text
                style={{ fontSize: 18, fontWeight: "bold", color: "black" }}
              >
                {generallyAgreePercentage.toFixed(0)}%
              </Text>
              <Text style={{ fontSize: 12, color: "#10b981" }}>Agreed</Text>
            </>
          ) : (
            <>
              <Text
                style={{ fontSize: 18, fontWeight: "bold", color: "black" }}
              >
                {generallyDisagreePercentage.toFixed(0)}%
              </Text>
              <Text style={{ fontSize: 12, color: "#dc2626" }}>Disagreed</Text>
            </>
          )}
        </View>
      </View>

      {/* Table Body */}
      {renderRow("Strongly agree", stronglyAgreeCount, totalRatings, "#16a34a")}
      {renderRow("Agree", agreeCount, totalRatings, "#22c55e")}
      {renderRow("Neutral", neutralCount, totalRatings, "#facc15")}
      {renderRow("Disagree", disagreeCount, totalRatings, "#f87171")}
      {renderRow(
        "Strongly disagree",
        stronglyDisagreeCount,
        totalRatings,
        "#dc2626"
      )}
    </View>
  );
};

export default SurveyResultBox;
