import React from "react";
import CountUp from "react-countup";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import FavourabilityChart from "../../FavourabilityChart";
import { classNames } from "../../../utilities/general";
import ProductImage from "../../ProductImage";

const SurveyListItem = ({ survey, product }) => {
  const formattedDate = new Date(survey.createdAt)?.toLocaleDateString(
    "en-GB",
    { day: "numeric", month: "long", year: "2-digit" }
  );

  const closedDate = survey.closedDate;
  const dateFormatter = new Intl.DateTimeFormat("en-GB");
  const surveyClosed = closedDate < new Date().toISOString();

  return (
    <Link to={`/employee-insights/product-survey/${survey.uniqueCode}`}>
      <div className="bg-white p-6 sm:p-12 rounded overflow-hidden group cursor-pointer">
        <div className="w-full">
          <div>
            <span
              className={classNames(
                survey.subType === "pre-implementation"
                  ? "bg-cyan-100 text-cyan-700"
                  : "bg-indigo-100 text-indigo-700",
                "inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium"
              )}
            >
              {survey.subType === "pre-implementation"
                ? "Pre-implementation"
                : "Post-implementation"}
            </span>
          </div>
          <div className="flex flex-wrap gap-x-4 items-center mt-2">
            <h3 className="text-2xl font-semibold mt-1 text-slate-900 group-hover:underline underline-offset-2 decoration-2">
              {formattedDate}
            </h3>
            <div className="flex gap-2 items-center text-base flex-shrink-0 mt-1">
              <span className="group-hover:underline underline-offset-2 decoration-2 text-center">
                View all results
              </span>
              <ArrowRightIcon className="h-4 w-4" />
            </div>
          </div>

          <div className="flex flex-col gap-4 lg:flex-row justify-between mt-4">
            <div className="flex gap-5 mt-1">
              <span className="relative flex h-4 w-4 mt-2">
                <span
                  className={classNames(
                    surveyClosed ? "bg-red-400" : "bg-indigo-400",
                    "animate-ping absolute inline-flex h-full w-full rounded-full  opacity-75"
                  )}
                ></span>
                <span
                  className={classNames(
                    surveyClosed ? "bg-red-500" : "bg-indigo-500",
                    "relative inline-flex rounded-full h-4 w-4 "
                  )}
                ></span>
              </span>
              <div className="w-full">
                <h3 className="font-semibold text-lg">
                  Your survey {surveyClosed ? "has finished" : "is live"}
                </h3>
                {closedDate && (
                  <p>
                    Submissions {surveyClosed ? "closed" : "close"} on{" "}
                    <span className="font-semibold">
                      {dateFormatter.format(new Date(closedDate))}
                    </span>
                  </p>
                )}
              </div>
            </div>

            <div>
              <dl className="flex flex-row gap-4 justify-between">
                <div className="flex flex-col-reverse">
                  <dt className="text-base text-slate-800 leading-5">
                    Survey responses
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-indigo-500">
                    <CountUp end={survey?.responseCount} duration={3} />
                  </dd>
                </div>

                <div className="flex flex-col-reverse">
                  <dt className="text-base text-slate-800 leading-5">
                    Questions answered
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-green-500">
                    <CountUp end={survey?.answerCount} duration={3} />
                  </dd>
                </div>

                <div className="flex flex-col-reverse">
                  <dt className="text-base text-slate-800 leading-5">
                    Comments received
                  </dt>
                  <dd className="text-3xl font-semibold tracking-tight text-yellow-400">
                    <CountUp end={survey?.commentCount} duration={3} />
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {!!survey?.averageFavourablility?.total && (
            <div className="min-h-[6rem] p-6 bg-indigo-50 rounded mt-4">
              <p className="text-slate-800 text-base font-semibold">
                Survey snapshot
              </p>

              <div className="text-green-600 text-sm mt-2">
                Favourable responses
              </div>
              <div className="mt-1">
                <FavourabilityChart
                  favourable={survey.averageFavourablility.favourable}
                  neutral={survey.averageFavourablility.neutral}
                  unfavourable={survey.averageFavourablility.unfavourable}
                  total={survey.averageFavourablility.total}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default SurveyListItem;
