import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { useNavigate, useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";

import LoadBox from "../../LoadBox";
import useStackList from "../../../hooks/useStackList";
import useProducts from "../../../hooks/useProducts";
import ResultsHeatmap from "./ResultsHeatmap";
import surveyQuestions from "../../data/surveyQuestions";
import ProductComments from "./ProductComments";
import ResultsOverview from "./ResultsOverview";
import ResultsHeatmapKey from "../../ResultsHeatmapKey";
import Dialog from "../../Dialog";
import RemoveSurveyDialog from "../../RemoveSurveyDialog";
import HeatmapSummary from "../../HeatmapSummary";
import HeaderSection from "./HeaderSection";
import SurveyAnswerRating from "../../SurveyAnswerRating";
import SurveyAnswerComment from "../../SurveyAnswerComment";
import {
  ArrowDownTrayIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import ProductSummary from "./ProductSummary";
import ClinicalSafetySummary from "./ClinicalSafetySummary";
import Button from "../../Button";
import { ExportSurveyPDF } from "./exportSurveyPDF";

const ProductSurveyDetailsPage = ({ cognitoUser, cognitoUserAttributes }) => {
  const [loadingSurvey, setLoadingSurveys] = useState();
  const [deleteSurveyDialogOpen, setDeleteSurveyDialogOpen] = useState(false);
  const [survey, setSurvey] = useState([]);
  const { surveyUniqueCode } = useParams();
  const { loadingStackList, stackList } = useStackList(cognitoUser);
  const { loadingProducts, products } = useProducts();
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    const getSurvey = async () => {
      setLoadingSurveys(true);

      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      const restOperation = get({
        apiName: "bordercrossRest",
        path: `/surveys/productSurvey/${surveyUniqueCode}`,
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
        },
      });
      const response = await restOperation.response;
      const survey = await response.body.json();
      setSurvey(survey);
      setLoadingSurveys(false);
    };

    getSurvey();
  }, []);

  // Select a product from survey to default as selected
  useEffect(() => {
    if (!selectedProduct && products && survey?.productIds?.length) {
      const product = products.find(
        (product) => product.id === survey.productIds[0]
      );
      setSelectedProduct(product);
    }
  }, [survey, products]);

  if (loadingSurvey || loadingStackList || loadingProducts) return <LoadBox />;

  const surveyStacks = stackList.filter((stack) =>
    survey.stackIds.includes(stack.id)
  );

  const isSiteAdmin = cognitoUserAttributes?.isSiteAdmin;

  const productHeatmapSummaries = survey.commentSummaries.find(
    (heatmapSummary) => heatmapSummary.productId === selectedProduct.id
  );
  let parsedHeatmapSummaries, parsedClinicalSafetySummary;
  try {
    parsedHeatmapSummaries = JSON.parse(productHeatmapSummaries?.summaryText);
    parsedClinicalSafetySummary = JSON.parse(survey?.clinicalSafetySummary);
  } catch (e) {}

  const surveyCompletions = survey?.byProduct?.find(
    (product) => product.productId === selectedProduct.id
  )?.completions;

  const productQuestions =
    surveyQuestions?.productSurvey?.[survey.subType]?.productQuestions;

  const hasResults =
    productQuestions?.filter((question) => question.type === "rating").length >
    0;

  return (
    <div className="mx-auto max-w-8xl mb-10 bg-white">
      <HeaderSection
        product={selectedProduct}
        surveyStacks={surveyStacks}
        survey={survey}
        setDeleteSurveyDialogOpen={setDeleteSurveyDialogOpen}
      />

      {surveyCompletions?.length > 0 && (
        <div className="hidden md:flex flex-1 items-center justify-center sm:items-stretch sm:justify-between z-10 px-4 py-5 sm:py-8 sm:px-12 sticky top-0 bg-white shadow-sm">
          <div className="sm:space-x-8 text-lg">
            {parsedHeatmapSummaries && (
              <Link
                activeClass="!border-indigo-500 text-gray-900"
                to="summary"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="cursor-pointer inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Summary
              </Link>
            )}
            {hasResults && (
              <Link
                activeClass="!border-indigo-500 text-gray-900"
                to="results"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="cursor-pointer inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Results
              </Link>
            )}

            <Link
              activeClass="!border-indigo-500 text-gray-900"
              to="comments"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="cursor-pointer inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
              Comments
            </Link>
            {parsedClinicalSafetySummary && (
              <Link
                activeClass="!border-indigo-500 text-gray-900"
                to="clinical-safety"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                className="cursor-pointer inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700"
              >
                Clinical Safety Analysis
              </Link>
            )}
          </div>
          <div>
            <PDFDownloadLink
            className="group inline-flex items-center justify-center rounded-md font-semibold bg-white ring-1 ring-inset ring-gray-300 text-slate-900 hover:bg-gray-50 py-2 px-4 text-sm"
              document={
                <ExportSurveyPDF
                  product={selectedProduct}
                  survey={survey}
                  stack={surveyStacks?.[0]}
                  parsedHeatmapSummaries={parsedHeatmapSummaries}
                  questions={productQuestions}
                />
              }
              fileName={`${selectedProduct.name}-survey-${survey.createdAt.slice(0,10)}.pdf`}
            >
              {() => (
                  <>
                    <ArrowDownTrayIcon
                      className="h-5 w-5 mr-1"
                      aria-hidden="true"
                    />
                    <span>Download as PDF</span>
                  </>
                )
              }
            </PDFDownloadLink>
          </div>
        </div>
      )}

      <div className="px-4 py-5 sm:py-12 sm:px-12">
        {surveyCompletions?.length > 0 ? (
          <>
            {parsedHeatmapSummaries && (
              <div id="summary" className="mb-14">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                  Summary
                </h2>

                <div className="bg-slate-50 rounded-lg mt-6 p-4 md:p-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {parsedHeatmapSummaries && (
                      <div className="mt-2">
                        <ProductSummary
                          survey={survey}
                          product={selectedProduct}
                          isSiteAdmin={isSiteAdmin}
                        />
                      </div>
                    )}

                    <div>
                      <div id="heatmap">
                        <ResultsHeatmapKey />
                        <div className="mt-2">
                          <ResultsHeatmap
                            selectedProduct={selectedProduct}
                            survey={survey}
                            surveyQuestions={productQuestions?.filter(
                              (question) => question.type === "rating"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {hasResults && (
              <div id="results" className="mb-14">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                  Results
                </h2>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                  {productQuestions
                    ?.filter((question) => question.type === "rating")
                    .map((question) => {
                      const answers = [];
                      surveyCompletions?.forEach((completion) =>
                        completion.answers?.map((answer) => {
                          if (answer.questionId === question.id)
                            answers.push(answer);
                        })
                      );
                      const ratings = answers.map((answer) => answer.rating);

                      return (
                        <SurveyAnswerRating
                          question={question.question.replace(
                            "<<softwareName>>",
                            selectedProduct.name
                          )}
                          ratings={ratings}
                        />
                      );
                    })}
                </div>
              </div>
            )}

            <div id="comments" className="mb-14">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                Comments
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                {productQuestions
                  ?.filter((question) =>
                    surveyCompletions.find((completion) =>
                      completion.answers.find(
                        (answer) =>
                          answer.comment && question.id === answer.questionId
                      )
                    )
                  )
                  .map((question) => {
                    const answers = [];
                    surveyCompletions.forEach((completion) =>
                      completion.answers?.map((answer) => {
                        if (answer.questionId === question.id)
                          answers.push({
                            ...answer,
                            role: completion.role,
                          });
                      })
                    );
                    const comments = answers
                      .filter((answer) => answer.comment)
                      .map((answer) => {
                        return {
                          comment: answer.comment,
                          sentiment: answer.commentSentiment,
                          role: answer.role,
                        };
                      });

                    return (
                      <SurveyAnswerComment
                        question={question.question.replace(
                          "<<softwareName>>",
                          selectedProduct.name
                        )}
                        comments={comments}
                      />
                    );
                  })}
              </div>
            </div>

            {parsedClinicalSafetySummary && (
              <div id="clinical-safety" className="mb-14">
                <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-3xl">
                  Clinical Safety Analysis
                </h2>

                <div className="mt-6">
                  <ClinicalSafetySummary
                    summary={parsedClinicalSafetySummary}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <ChatBubbleLeftEllipsisIcon className="max-w-sm max-h-sm text-slate-200" />
            <h2 className="text-2xl text-slate-400">No responses received</h2>
          </div>
        )}

        <Dialog
          open={deleteSurveyDialogOpen}
          setOpen={setDeleteSurveyDialogOpen}
        >
          <RemoveSurveyDialog
            surveyId={survey.id}
            setOpen={setDeleteSurveyDialogOpen}
            surveyEndpointPath="productSurvey"
          />
        </Dialog>
      </div>
    </div>
  );
};

export default ProductSurveyDetailsPage;
